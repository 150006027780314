import "@/scripts/theme"
import "@/scripts/custom"

const init = async () => {
  const { default: Alpine } = await import("alpinejs")

  if (window.location.pathname.includes("/pages/contact")) {
    const { default: ContactForm } = await import("~/components/contact-form/index")
    Alpine.plugin(ContactForm)
  }

  const { default: DeliveryEstimate } = await import("~/components/delivery-estimate/index")
  Alpine.plugin(DeliveryEstimate)

  const { default: CartAttributes } = await import("~/components/cart-attributes/index")
  Alpine.plugin(CartAttributes)

  window.Alpine = Alpine
  Alpine.start()
}

init()