import Bugsnag from "@bugsnag/js"
import BugsnagPerformance from "@bugsnag/browser-performance"

const bugsnag = Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
  releaseStage: import.meta.env.VITE_STAGE,
  enabledReleaseStages: ['production', 'staging', 'local'],
  trackInlineScripts: true,
  maxBreadcrumbs: 5,
})

BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY })

window.Bugsnag = bugsnag

export default bugsnag